@use "../../global.scss" as *;
@use "../components/Nav.module.scss" as nav;

.readingList {
  // position: absolute;
  margin: 20px;
  margin-top: nav.$mobileHeight;
  // padding: calc(nav.$mobileHeight + 15px) 20px 20px 20px;
  // top: 0;
  // left: 0;
  // width: 100vw;
  // height: 100vh;

  font-family: $font-sans-serif;
}


.embla {
  overflow: hidden;
}

.emblaContainer {
  display: flex;
  gap: 20px;
}

.emblaSlide {
  flex: 0 0 100%;
  min-width: 0;
  padding: 25px;
  border-radius: 20px;
  background-color: #F003;

  // margin-left: 20px;
  // &:nth-child(1) {
    // margin: 0 10px;
  // }
}

.book {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  img {
    width: 100%;
  }

  h4 {
    font-weight: 200;
    text-transform: uppercase;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  p {
    margin-bottom: 5px;
  }
}


.seriesChip {
  transition: transform 0.2s, box-shadow 0.2s;
  font-weight: bold;
  filter: drop-shadow(0px 3px 10px #1117);

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px #bbb;
  }

  :global(.dark) & {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}


.imageStack {
  $transitionDuration: 0.4s;

  position: relative;
  transition: width $transitionDuration;
  cursor: pointer;

  img:first-child {
    position: relative;
  }

  img {
    position: absolute;
    transform: perspective(1000px) rotateY(-15deg);
    transition:
      left $transitionDuration,
      transform $transitionDuration;
    object-fit: cover;
  }
}


.bookEntry {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  padding: 24px 32px;

  &.inProgress {
    background-color: hsl(223deg, 16%, 83%);
    :global(.dark) & {
      background-color: #313244;
    }
    border-radius: 10px;
    grid-template-rows: auto 1fr;

    .inProgressLabel {
      grid-column: span 2;
      text-transform: uppercase;
      font-weight: bold;
      color: hsl(223deg, 16%, 30%);
      letter-spacing: 1px;

      :global(.dark) & {
        color: #99a;
      }
    }
  }

  @media (max-width: $mobileWidth) {
    grid-template-columns: auto;
    padding: 24px 24px;
    
    &.inProgress {
      grid-template-rows: repeat(3, auto);
      
      .inProgressLabel {
        grid-column: unset;
      }
    }
  }

  .bookCover {
    @media (max-width: $mobileWidth) {
      @include flexCenter;
    }

    img {
      position: relative;
      height: 300px;
      filter: drop-shadow(0px 3px 10px #111c);

      @media (min-width: $mobileWidth) {
        $ease-in: cubic-bezier(0, 0, 0.4, 1);
        $ease-out: ease-out;

        height: 250px;
        transition:
          transform 0.3s $ease-out,
          filter 0.3s $ease-out;
        cursor: pointer;
        transform: perspective(1000px) rotateY(15deg) rotateX(3deg);
        $shadow-color: #030307;
        filter: drop-shadow(-2px 3px 10px $shadow-color);

        &:hover {
          transform: scale(1.05);
          filter: drop-shadow(0px 5px 13px #404040);
          transition:
            transform 0.2s $ease-in,
            filter 0.2s $ease-in;
        }
      }
    }
  }

  .bookDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;

    h4 {
      font-weight: 200;
      text-transform: uppercase;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: bold;
    }

    p {
      margin-bottom: 5px;
      text-align: justify;
    }

    @media (max-width: $mobileWidth) {
      h3, h4, p {
        text-align: center;
      }
    }

    .chips {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      @media (max-width: $mobileWidth) {
        justify-content: center;
      }
    }
  }
}


.focusedImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadeIn 0.2s;
  background-color: #000B;
  backdrop-filter: blur(2px);

  img {
    max-height: 90vh;
  }

  button {
    position: absolute;
    top: 75px;
    left: 30px;
    background: none;
    border: none;
    cursor: pointer;

    img {
      transition: filter 0.1s;
      filter: invert(100%);
      width: 30px;
      height: 30px;

      &:hover {
        filter: invert(70%);
      }
    }
  }
}
