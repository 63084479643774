@use "../../global.scss" as *;

// Brittle workaround for making pages clear the nav.
$mobileHeight: 49px;

.desktopNav, .mobileNav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 15px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: $font-sans-serif;
  font-weight: lighter;
  z-index: 999;

  h1 {
    font-size: 24px;
    font-weight: lighter;
    flex: 1;
    text-align: left;
    color: $lightThemeText;
    
    :global(.dark) & {
      color: $darkThemeText;
    }

    a {
      transition: color 0.2s;
      text-decoration: none;
      color: inherit;

      &:hover {
        color: #ccc;
      }
    }
  }

  nav {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}


.mobileNav {
  position: fixed;
  width: 100vw;

  h1 {
    text-align: center;
  }

  > img {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 30px;

    :global(.dark) & {
      filter: invert(100%);
    }
  }

  .openMenu {
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    display: flex;
    justify-content: flex-end;

    background-color: #0003;
    backdrop-filter: blur(2px);
    animation: fadeIn 200ms;

    .menuTray {
      $width: 200px;

      position: relative;
      width: $width;
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-shadow: 0px 0px 15px #050515;
      background-color: $lightThemeBg;

      :global(.dark) & {
        background-color: $darkThemeBg;
      }

      @keyframes slideIn {
        from { transform: translateX($width); }
        to { transform: none; }
      }

      animation: slideIn 200ms;

      a {
        display: block;
        text-decoration: none;

        span {
          font-weight: 200;
          gap: 6px;
          font-size: 20px;
          color: white;
          display: flex;

          img {
            display: inline-block;
            height: 1.3rem;
            filter: invert(100%);
          }
        }
      }
    }
  }
}

.glassButton {
  // Wipe animation adapted from this Codepen by Ryan Altvater: https://codepen.io/ryanaltvater/pen/vNgjNW
  position: relative;
  padding: 8px;
  width: auto;
  cursor: pointer;
  text-decoration: none;
  backdrop-filter: blur(3px);

  img {
    display: inline-block;
    height: 1.3rem;
    
    :global(.dark) & {
      filter: invert(100%);
    }
  }

  &:after {
    position: absolute;
    z-index: 2;
    content: '';
    transition: all 0.1s;

    left: 0;
    width: 100%;
    height: 1px;

    bottom: 0;
    background-color: $lightThemeText;
    mix-blend-mode: difference;
    backdrop-filter: invert(100%);
  }

  :global(.dark) &:after {
    background-color: white;
    backdrop-filter: none;
  }

  &:hover:after {
    height: 100%;
  }

  span {
    display: flex;
    align-items: center;
    font-weight: 200;
    gap: 6px;
    font-size: 20px;
    color: black;
    text-align: center;

    :global(.dark) & {
      color: white;
    }
  }

  &.active {
    background-color: black;
    color: white;

    &:global(.dark) {
      background-color: white;
      color: black;
    }
  }
}
